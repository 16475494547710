/**
 * @fileOverview This file contains all the necessary field values to manage qbraid qbook
 * @module qbraidConstants
 */

import { QBOOK_DOMAIN_VALUES } from "../../constants/domainNameValues";
import qbraidLogoLightTheme from "../../../assets/img/qbraid_light_theme_logo.png";
import qbraidLogoDarkTheme from "../../../assets/img/qbraid_dark_theme_logo.png";
import qbraidSimpleLogo from "../../../assets/img/qbraid_simple_logo.png";
import { DomainObjectType } from "../../../types/domainType";
import qbraidFavicon from "../../../assets/img/faviconQbraid.png";
import { MUIComponent } from "../../MUITheme/component";
import { qbraidBasePalette } from "../../MUITheme/palette";
import quantum from "../../../assets/img/quantum.png";
import lightUnauthorized from "../../../assets/img/Unauthorised.jpg";
import darkUnauthorized from "../../../assets/img/Unauthorised.jpg";
import Account from "../../../assets/img/Account.svg";
import userIcon from "../../../assets/img/UsersLogo.svg";

import {
  adminCourseOverviewData,
  adminDashBoardData,
  adminNavData,
} from "../common/commonAdminFields";

const qBraidConstants: DomainObjectType = {
  domain: ".qbraid.com",
  domainName: QBOOK_DOMAIN_VALUES.qbraid,
  lightThemeLogo: qbraidLogoLightTheme,
  darkThemeLogo: qbraidLogoDarkTheme,
  fullLogoDimension: {
    width: 102,
    height: 40,
  },
  simpleLogo: qbraidSimpleLogo,
  favicon: qbraidFavicon,
  docsUrl: "https://docs.qbraid.com/projects/lab/en/latest/",
  awsCognito: {
    cognitoPoolId: "us-east-1_7hq9OmpnT",
    cognitoClientId: "70fo00fpob1sd133m98k7b0jan",
    cognitoDomain: "auth.qbraid.com",
    cognitoLogoutUrl: `https://auth.qbraid.com/logout?client_id=70fo00fpob1sd133m98k7b0jan&logout_uri=`,
  },
  apiDomain: "qbraid",
  footer: {
    copyright: "Copyright © 2024 qBraid. All rights reserved.",
    termsOfUse: "Terms & conditions",
    privacyPolicy: "Privacy Policy",
    cookiePolicy: "Cookie Policy",
  },
  socialMedia: {
    discordLink: "https://discord.gg/9jpmpeEV65",
    twitterLink: "https://twitter.com/qbraid_official",
    githubLink: "https://github.com/qbraid",
    linkedinLink: "https://www.linkedin.com/company/qbraid-official/",
  },
  landingPage: {
    bannerImage: {
      image: quantum,
      height: "200%",
      width: "auto",
      positionLeft: "-06px",
      positionTop: "-160px",
    },
    bannerHeading: "Welcome to the world of quantum courses!",
    bannerText: "Explore more about quantum technologies using qBook.",
    whyChoose: {
      title: "Why Choose Us?",
      text: "Dive into Online Quantum Courses covering Diverse Subjects and Applications.",
      whyChooseCards: [
        {
          id: "crs",
          label: "Earn a qBraid Certificate",
          value: "Tracks your quantum learning journey and achievements throughout different technologies.",
          bgColor: "linear-gradient(195deg,#ec407a,#d81b60)",
        },
        {
          id: "std",
          label: "Access to Quantum Computing",
          value: "Run your code on real quantum computers from the world's leading hardware providers.",
          bgColor: "linear-gradient(195deg,#42424a,#191919)",
        },
        {
          id: "cls",
          label: "Diverse Course Selection",
          value: "Choose from a wide range of quantum computing courses covering applications in diverse industries.",
          bgColor: "linear-gradient(195deg,#66bb6a,#43a047)",
        },
        {
          id: "org",
          label: "Interactive Learning Experience",
          value: "Code, visualize, and interact with quantum computing directly from your browser!",
          bgColor: "linear-gradient(195deg,#49a3f1,#1a73e8)",
        },
      ],
    },
    listCourse: {
      title: "Start here",
      text: "Discover Our Most Popular Quantum Courses",
    },
    listBlogs: {
      title: "Blogs",
      text: "Read and write code intuitively with qBook Blogs, covering the latest developments in quantum computing,",
    },
    listTutors: {
      title: "Our Teachers",
      text: "Those who know, do. Those that understand, teach. Learn from the best quantum computing educators.",
    },
  },
  blogs: {
    blogHeaderR: 192,
    blogHeaderG: 64,
    blogHeaderB: 192,
    blogHeaderTimeDelay: 0.01,
    blogHeaderTitle: "Blogs.",
    blogHeaderText:
      "qBraid Blogs are interactive Jupyter notebooks intended for sharing with a large audience in and out of the qBraid community.",
  },
  errorPage: {
    title:
      "Something went wrong... Sorry for the inconvenience caused! Please let us know if this persists.",
    accordionTitle: "View",
  },
  notFoundTemplate: {
    text: "Sorry, we're unable to find the item you were looking for. Check out some blogs or navigate back to your account: ",
  },
  admin: {
    certificate: {
      certBgImageUrl:
        "https://qbraid-static.s3.amazonaws.com/certificates/qbraid.jpg",
      styles: {
        name: {
          fontSize: 24,
          bold: true,
          color: "#000000",
          alignment: "center",
          margin: [0, 275, 0, 0],
        },
        courseTitle: {
          fontSize: 24,
          color: "#000000",
          bold: true,
          italics: true,
          alignment: "center",
          margin: [0, 50, 0, 0],
        },
        date: {
          fontSize: 14,
          color: "#000000",
          margin: [0, 50, 20, 0],
          alignment: "right",
        },
      },
      pageOrientation: "landscape",
    },
    unauthorized: {
      unauthorizedText: "You are not allowed to access this page.",
      unauthorizedImage: {
        lightThemeImage: {
          image: lightUnauthorized,
          width: "500px",
          height: "500px",
        },
        darkThemeImage: {
          image: darkUnauthorized,
          width: "500px",
          height: "500px",
        },
      },
    },
    navigationBar: {
      tabData: [
        {
          label: "Account",
          icon: Account,
          url: "https://account.qbraid.com/",
        },
        ...adminNavData,
      ],
    },
    dashBoard: {
      adminDashBoardCardData: [
        {
          label: "Users",
          text: "Click to manage Users",
          icon: userIcon,
          handler: () => {
            window.open(`https://lab.qbraid.com/hub/admin`, "_blank");
          },
        },
        ...adminDashBoardData,
      ],
    },
    courses: {
      courseOverviewCardData: [...adminCourseOverviewData],
    },
    blogs: {
      welcomeText: "Welcome back..",
    },
  },
  MUI: {
    component: MUIComponent,
    palette: qbraidBasePalette,
  },
};
export default qBraidConstants;
