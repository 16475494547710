/**
 * @fileOverview This file contains all the necessary field values to manage questeam qbook
 * @module questeamConstants
 */

import { QBOOK_DOMAIN_VALUES } from "../../constants/domainNameValues";
import qusteamLogoLightTheme from "../../../assets/img/qusteam_light_theme_logo.png";
import qusteamLogoDarkTheme from "../../../assets/img/qusteam_dark_theme_logo.png";
import qusteamSimpleLogo from "../../../assets/img/qusteam_simple_logo.jpg";
import qusteamFavicon from "../../../assets/img/faviconQusteam.jpeg";
import { DomainObjectType } from "../../../types/domainType";
import { MUIComponent } from "../../MUITheme/component";
import { qusteamBasePalette } from "../../MUITheme/palette";
import quantum from "../../../assets/img/quantum.png";
import lightUnauthorized from "../../../assets/img/Unauthorised.jpg";
import darkUnauthorized from "../../../assets/img/Unauthorised.jpg";
import Account from "../../../assets/img/Account.svg";
import userIcon from "../../../assets/img/UsersLogo.svg";
import {
  adminCourseOverviewData,
  adminDashBoardData,
  adminNavData,
} from "../common/commonAdminFields";

const qusteamConstants: DomainObjectType = {
  domain: ".qusteam.org",
  domainName: QBOOK_DOMAIN_VALUES.qusteam,
  lightThemeLogo: qusteamLogoLightTheme,
  darkThemeLogo: qusteamLogoDarkTheme,
  fullLogoDimension: {
    width: 275,
    height: 132,
  },
  simpleLogo: qusteamSimpleLogo,
  favicon: qusteamFavicon,
  awsCognito: {
    cognitoPoolId: "us-east-1_dpl4rADNO",
    cognitoClientId: "4igp804jk3cabvprcn14i8j8kf",
    cognitoDomain: "auth.qusteam.org",
    cognitoLogoutUrl: `https://auth.qusteam.org/logout?client_id=4igp804jk3cabvprcn14i8j8kf&logout_uri=`,
  },
  apiDomain: "qusteam",
  careerUrl: "https://qusteam.org/for-learners",
  contactUsUrl: "https://qusteam.org/contact",
  docsUrl: "https://docs.qbraid.com/projects/lab/en/latest/",
  footer: {
    cookiePolicy: "Cookie Policy",
    copyright: "Copyright © 2024 QuSteam. All rights reserved.",
    privacyPolicy: "Privacy Policy",
    termsOfUse: "Terms and conditions",
  },
  socialMedia: {
    discordLink: "",
    twitterLink: "https://twitter.com/QuSTEAM",
    githubLink: "",
    linkedinLink: "https://www.linkedin.com/company/qusteam/",
  },
  landingPage: {
    bannerImage: {
      image: quantum,
      height: "200%",
      width: "auto",
      positionLeft: "-6px",
      positionTop: "-160px",
    },
    bannerHeading: "Welcome to the world of quantum courses on QuSTEAM.",
    bannerText: "Explore more about quantum technology using qBook, powered by qBraid.",
    whyChoose: {
      title: "Why Choose Us",
      text: "Dive into beginner-friendly Online Quantum Courses to get you up to speed",
      whyChooseCards: [
        {
          id: "crs",
          label: "Earn a QuSTEAM Certificate",
          value: "Tracks your quantum learning journey and achievements through different topics.",
          bgColor: "linear-gradient(195deg,#ec407a,#d81b60)",
        },
        {
          id: "std",
          label: "Access to Quantum Computing",
          value: "Run your code on real quantum computers, spanning the leading hardware providers.",
          bgColor: "linear-gradient(195deg,#42424a,#191919)",
        },
        {
          id: "cls",
          label: "Diverse Course Selection",
          value: "Choose from a wide range of quantum computing courses, from fundamental building blocks to algorithm design.",
          bgColor: "linear-gradient(195deg,#66bb6a,#43a047)",
        },
        {
          id: "org",
          label: "Interactive Learning Experience",
          value: "Instructors, QISE researchers, industry professionals, and education researchers can engage in the creation, teaching, and development of QuSTEAM curricula.",
          bgColor: "linear-gradient(195deg,#49a3f1,#1a73e8)",
        },
      ],
    },
    listCourse: {
      title: "Start here",
      text: "Discover Our Most Popular Quantum Courses",
    },
    listBlogs: {
      title: "Blogs",
      text: "Read and write code intuitively with qBook blogs, covering the latest developments in quantum computing.",
    },
    listTutors: {
      title: "Our Teachers",
      text: "Those who know, do. Those that understand, teach. Learn from the best quantum computing educators.",
    },
  },
  blogs: {
    blogHeaderR: 5,
    blogHeaderG: 3,
    blogHeaderB: 100,
    blogHeaderTimeDelay: 0.01,
    blogHeaderTitle: "Blogs.",
    blogHeaderText:
      "QuSTEAM blogs are interactive Jupyter notebooks intended for sharing with a large audience in and out of the qBraid community.",
  },
  errorPage: {
    title:
      "Something went wrong... sorry for the inconvenience caused! Please let us know if this persists.",
    accordionTitle: "View",
  },
  notFoundTemplate: {
    text: "Sorry, we're unable to find the item you were looking for. Check out some blogs or navigate back to your account:",
  },
  MUI: {
    component: MUIComponent,
    palette: qusteamBasePalette,
  },
  admin: {
    certificate: {
      certBgImageUrl:
        "https://qbraid-static.s3.amazonaws.com/certificates/qusteam.jpg",
      styles: {
        name: {
          fontSize: 24,
          bold: true,
          color: "#000000",
          alignment: "center",
          margin: [0, 275, 0, 0],
        },
        courseTitle: {
          fontSize: 24,
          color: "#000000",
          bold: true,
          italics: true,
          alignment: "center",
          margin: [0, 50, 0, 0],
        },
        date: {
          fontSize: 14,
          color: "#000000",
          margin: [0, 50, 20, 0],
          alignment: "right",
        },
      },
      pageOrientation: "landscape",
    },
    unauthorized: {
      unauthorizedText: "You are not allowed to access this page.",
      unauthorizedImage: {
        lightThemeImage: {
          image: lightUnauthorized,
          width: "500px",
          height: "500px",
        },
        darkThemeImage: {
          image: darkUnauthorized,
          width: "500px",
          height: "500px",
        },
      },
    },
    navigationBar: {
      tabData: [
        {
          label: "Account",
          icon: Account,
          url: "https://account.qusteam.org/",
        },
        ...adminNavData,
      ],
    },
    dashBoard: {
      adminDashBoardCardData: [
        {
          label: "Users",
          text: "Click to manage Users",
          icon: userIcon,
          handler: () => {
            window.open(`https://lab.qbraid.com/hub/admin`, "_blank");
          },
        },
        ...adminDashBoardData,
      ],
    },
    courses: {
      courseOverviewCardData: [...adminCourseOverviewData],
    },
    blogs: {
      welcomeText: "Welcome back.",
    },
  },
};
export default qusteamConstants;
