/**
 * @fileOverview This file contains all the necessary field values to manage quera qbook
 * @module queraConstants
 */

import queraLogoLightTheme from "../../../assets/img/quera_light_theme_logo.png";
import queraLogoDarkTheme from "../../../assets/img/quera_dark_theme_logo.svg";
import queraSimpleLogo from "../../../assets/img/quera_simple_logo.jpg";
import queraFavicon from "../../../assets/img/faviconQuera.png";
import { QBOOK_DOMAIN_VALUES } from "../../constants/domainNameValues";
import { DomainObjectType } from "../../../types/domainType";
import { MUIQueraComponent } from "../../MUITheme/component";
import { queraBasePalette } from "../../MUITheme/palette";
import queraAquila from "../../../assets/img/quera_aquila.webp";
import lightUnauthorized from "../../../assets/img/Unauthorised.jpg";
import darkUnauthorized from "../../../assets/img/Unauthorised.jpg";
import Account from "../../../assets/img/Account.svg";
import userIcon from "../../../assets/img/UsersLogo.svg";
import {
  adminCourseOverviewData,
  adminDashBoardData,
  adminNavData,
} from "../common/commonAdminFields";

const queraConstants: DomainObjectType = {
  domain: ".quera.com",
  domainName: QBOOK_DOMAIN_VALUES.quera,
  lightThemeLogo: queraLogoLightTheme,
  darkThemeLogo: queraLogoDarkTheme,
  fullLogoDimension: {
    width: 201,
    height: 37,
  },
  simpleLogo: queraSimpleLogo,
  favicon: queraFavicon,
  docsUrl: "https://docs.qbraid.com/projects/lab/en/latest/",
  careerUrl: "https://www.quera.com/careers",
  contactUsUrl: "https://www.quera.com/contact-us",
  awsCognito: {
    // these fields are for quera staging user pool in aws cognito
    // // for testing keeping qbraid user pool
    cognitoPoolId: "us-east-1_7YUtEtWME", // user pool might change
    cognitoClientId: "287qlvtdt8jt36frmr4f75eva1", // client id might change
    cognitoDomain: "auth.quera.com", // auth url might change
    cognitoLogoutUrl: `https://auth.quera.com/logout?client_id=287qlvtdt8jt36frmr4f75eva1&logout_uri=`,

    // cognitoPoolId: "us-east-1_7hq9OmpnT",
    // cognitoClientId: "70fo00fpob1sd133m98k7b0jan",
    // cognitoDomain: "auth.qbraid.com",
    // cognitoLogoutUrl: `https://auth.qbraid.com/logout?client_id=70fo00fpob1sd133m98k7b0jan&logout_uri=`,
  },
  apiDomain: "qbraid", // api domain might change
  footer: {
    copyright: `Copyright © 2024 ${QBOOK_DOMAIN_VALUES.quera}. All rights reserved.`,
    termsOfUse: "Terms & conditions",
    privacyPolicy: "Privacy Policy",
    cookiePolicy: "Cookie Policy",
  },
  socialMedia: {
    discordLink: "",
    twitterLink: "https://twitter.com/QueraComputing",
    githubLink: "https://github.com/QuEraComputing",
    linkedinLink: "https://www.linkedin.com/company/quera-computing-inc/",
  },
  landingPage: {
    bannerImage: {
      image: queraAquila,
      height: "400px",
      width: "400px",
      positionLeft: "-46px",
      positionTop: "-96px",
    },
    bannerHeading: `Welcome to Neutral Atom Quantum Computing with ${QBOOK_DOMAIN_VALUES.quera}.`,
    bannerText:
      "Explore more about our quantum technology using qBook, the educational platform powered by qBraid.",
    whyChoose: {
      title: "Why Choose Us?",
      text: "Take online training sessions to learn from the best in the industry.",
      whyChooseCards: [
        {
          id: "crs",
          label: `Earn a ${QBOOK_DOMAIN_VALUES.quera} Certificate`,
          value:
            "Learn how to program a quantum computer with the leading neutral atom hardware company",
          bgColor: "linear-gradient(195deg,#ec407a,#d81b60)",
        },
        {
          id: "std",
          label: "Accessibility and Convenience",
          value:
            "Use our Bloqade software in tailored courses to learn to use neutral atoms",
          bgColor: "linear-gradient(195deg, #5d12e3, #5f59f7)",
        },
        {
          id: "cls",
          label: "Diverse Course Selection",
          value: "Choose to learn from a wide range of quantum computing applications",
          bgColor: "linear-gradient(195deg,#66bb6a,#43a047)",
        },
        {
          id: "org",
          label: "Interactive Learning Experience",
          value: "Code, visualize, and interact with quantum computing from your browser",
          bgColor: "linear-gradient(195deg,#49a3f1,#1a73e8)",
        },
      ],
    },
    listCourse: {
      title: "Take a Course",
      text: "Learn how to use our 256 qubit quantum computer Aquila",
    },
    listBlogs: {
      title: "Blogs",
      text: "Read codable blogs on Quantum Computing, integrated with qBraid Lab",
    },
    listTutors: {
      title: "Our Teachers",
      text: "Learn from the best quantum educators in the industry",
    },
  },
  blogs: {
    blogHeaderR: 100,
    blogHeaderG: 55,
    blogHeaderB: 255,
    blogHeaderTimeDelay: 0.01,
    blogHeaderTitle: "Blogs.",
    blogHeaderText: `${QBOOK_DOMAIN_VALUES.quera} blogs are interactive Jupyter notebooks intended for sharing with a large audience, in and out of the qBraid community`,
  },
  errorPage: {
    title:
      "Something went wrong... Sorry for the inconvenience! Please let us know if this persists by emailing us at contact@qbraid.com",
    accordionTitle: "View",
  },
  notFoundTemplate: {
    text: "Sorry, we're unable to find the item you were looking for. Check out some blogs, or navigate back to your account: ",
  },
  MUI: {
    component: MUIQueraComponent,
    palette: queraBasePalette,
  },
  admin: {
    certificate: {
      certBgImageUrl:
        "https://qbraid-static.s3.amazonaws.com/certificates/quera.jpg",
      styles: {
        name: {
          fontSize: 48,
          bold: true,
          color: "#ffffff",
          alignment: "center",
          margin: [0, 235, 0, 0],
        },
        courseTitle: {
          fontSize: 24,
          bold: true,
          color: "#ffffff",
          italics: true,
          alignment: "center",
          margin: [0, 60, 0, 0],
        },
        date: {
          color: "#ffffff",
          fontSize: 14,
          margin: [130, 30, 0, 0],
          alignment: "left",
        },
      },
      pageOrientation: "landscape",
    },
    unauthorized: {
      unauthorizedText: "You are not allowed to access this page.",
      unauthorizedImage: {
        lightThemeImage: {
          image: lightUnauthorized,
          width: "500px",
          height: "500px",
        },
        darkThemeImage: {
          image: darkUnauthorized,
          width: "500px",
          height: "500px",
        },
      },
    },
    navigationBar: {
      tabData: [
        {
          label: "Home Page",
          icon: Account,
          url: "/",
        },
        ...adminNavData,
      ],
    },
    dashBoard: {
      adminDashBoardCardData: [
        ...adminDashBoardData,
        {
          label: "Users",
          text: "Click to manage Users",
          icon: userIcon,
          handler: () => {
            window.open(`https://lab.qbraid.com/hub/admin`, "_blank");
          },
        },
      ],
    },
    courses: {
      courseOverviewCardData: [...adminCourseOverviewData],
    },
    blogs: {
      welcomeText: "Welcome back!",
    },
  },
};
export default queraConstants;
