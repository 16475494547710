import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { LearnInfoType, PublicCourseType } from "../types/LearnTypes";
import { JupyterFile } from "../types/FileTypes";
import {
  getCourseAction,
  loadPublicCoursesAction,
  registerInToCourseAction,
} from "../middleware/learnMiddleware";

export interface LearnStoreState {
  _id: string;
  courseInfo: LearnInfoType[];
  courseMetaData: JupyterFile[] | any;
  courseLoading: boolean;
  courseLoadingFailed: boolean;
  courseLoadingSuccess: boolean;
  loadingPublicCourses: boolean;
  failedToLoadPublicCourses: boolean;
  listedPublicCourses: PublicCourseType[];
  remainingCourses: number;
}

type CourseResponseType = {
  _id: string;
  courseInfo: LearnInfoType[];
  courseData: string;
};

const initialState: LearnStoreState = {
  _id: "",
  courseInfo: [],
  courseMetaData: [],
  courseLoading: true,
  courseLoadingFailed: false,
  courseLoadingSuccess: false,
  listedPublicCourses: [],
  loadingPublicCourses: false,
  failedToLoadPublicCourses: false,
  remainingCourses: -1,
};

const courseDataToMetadata = (courseData: JupyterFile) => {
  /*
   * Parse the course jupyter notebook data into a metadata object.
   */
  courseData.cells = courseData.cells.map((cell, index) => {
    // maps cell indices so we can edit objects and save to filesystem cell-by-cell
    let metadata = cell.metadata;
    if (!metadata) {
      metadata = {};
    }
    metadata["cell_index"] = index;
    cell.metadata = metadata;
    return cell;
  });
  return courseData;
};

const learnSlice = createSlice({
  name: "learn",
  initialState: initialState,
  extraReducers: (builder) => {
    builder.addCase(getCourseAction.pending, (state) => {
      state.courseLoadingFailed = false;
      state.courseLoadingSuccess = false;
      state.courseLoading = true;
    });
    builder.addCase(
      getCourseAction.fulfilled,
      (state, action: PayloadAction<any>) => {
        const { _id, courseInfo, courseData }: CourseResponseType =
          action.payload;
        let courseMetaData = null;
        if (courseData !== undefined) {
          courseMetaData = courseDataToMetadata(JSON.parse(courseData));
        }
        state._id = _id;
        state.courseInfo = courseInfo;
        state.courseMetaData = courseMetaData;
        state.courseLoadingFailed = false;
        state.courseLoadingSuccess = true;
        state.courseLoading = false;
      },
    );
    builder.addCase(getCourseAction.rejected, (state, action) => {
      state._id = "";
      state.courseInfo = [];
      state.courseMetaData = [];
      state.courseLoadingFailed = true;
      state.courseLoadingSuccess = false;
      state.courseLoading = false;
    });

    // loading public courses
    builder.addCase(loadPublicCoursesAction.pending, (state) => {
      state.loadingPublicCourses = true;
      state.failedToLoadPublicCourses = false;
    });
    builder.addCase(
      loadPublicCoursesAction.fulfilled,
      (state, action: PayloadAction<any>) => {
        const { courseArray, remainingCourses } = action.payload;
        const combinedArray = [...state.listedPublicCourses, ...courseArray];
        const filteredArray = combinedArray.filter((obj, index) => {
          return (
            index ===
            combinedArray.findIndex((o) => obj.courseName === o.courseName)
          );
        });
        state.listedPublicCourses = filteredArray;
        state.remainingCourses = remainingCourses;
        state.loadingPublicCourses = false;
        state.failedToLoadPublicCourses = false;
      },
    );
    builder.addCase(loadPublicCoursesAction.rejected, (state, action) => {
      state.loadingPublicCourses = false;
      state.failedToLoadPublicCourses = true;
      state.remainingCourses = -1;
    });
    builder.addCase(registerInToCourseAction.fulfilled, (state, action) => {});
  },
  reducers: {
    setLoading: (state) => {
      state.courseLoading = true;
    },
    unsetLoading: (state) => {
      state.courseLoading = false;
    },
  },
});

export const { setLoading, unsetLoading } = learnSlice.actions;
export default learnSlice.reducer;
