/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import pdfmake from "pdfmake/build/pdfmake";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import {
  PageOrientation,
  StyleDictionary,
  TDocumentDefinitions,
} from "pdfmake/interfaces";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Skeleton,
  Slide,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import GroupIcon from "@mui/icons-material/Group";
import CloseIcon from "@mui/icons-material/Close";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../reduxStore/hooks";
import UserTableList from "./courseTable";
import {
  addCourseIdInToRefreshingCourses,
  removeCourseIdFromRefreshingCourses,
  setSelectedUser,
  setShowGrader,
} from "../../../../../../reduxStore/features/adminCourseSlice";
import AnswerAccordion, { QuestionTypes } from "./AnswerAccordion";
import {
  loadSingleCourseDataAction,
  retrieveStudentsSolutionInCourseForFreeResponseAction,
  retrieveStudentsSolutionInCourseForMcqAction,
  sendAttachedEmailAction,
  updateUserCertificateAction,
} from "../../../../../../reduxStore/middleware/adminCourseMiddleware";
import { CertificateIcon } from "./CertificateIcon";
import ImageIcon from "@mui/icons-material/Image";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import { DOMAIN_CONFIG_OBJ } from "../../../../../../utils/axiosInstance";
import { getBase64ImageFromURL } from "../../../../../../reduxStore/services/adminCourseServices";
import { handleSnackbarOpen } from "../../../../../../reduxStore/features/snackbarSlice";
import noPreviewAvailable from "../../../../../../assets/img/NoPreviewAvailable.jpg";

pdfmake.fonts = {
  Roboto: {
    normal:
      "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf",
    bold: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf",
    italics:
      "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf",
    bolditalics:
      "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf",
  },
};
const imageFormats = ["png", "jpeg", "jpg", "svg"];

// import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
// import CachedIcon from "@mui/icons-material/Cached";

type Props = {};
// type UserListType = {
//   userId: string;
//   userName: string;
//   progress: string;
//   lastActivity: string;
//   role: string;
//   frontEndId: string;
// };
const GraderUi = (props: Props) => {
  const {
    selectedCourse,
    refreshingCourses,
    selectedUser,
    selectedCourseUserList: userList,
  } = useAppSelector((state) => state.adminCourse);

  const [userProgressValue, setUserProgressValue] = useState<number>(0);
  const [loadingUserFrqAnswers, setLoadingUserFrqAnswers] =
    useState<boolean>(false);
  const [loadingUserMcqAnswers, setLoadingUserMcqAnswers] =
    useState<boolean>(false);
  // const [loadingUserCqAnswers, setLoadingUserCqAnswers] = useState(false);

  const [slideIn, setSlideIn] = useState<boolean>(true);
  const [selectedUserFrqResponse, setSelectedUserFrqResponse] = useState<
    Array<any>
  >([]);
  const [selectedUserMcqResponse, setSelectedUserMcqResponse] = useState<
    Array<any>
  >([]);
  // const [selectedUserCqResponse, setSelectedUserCqResponse] = useState([]);
  const [slideDirection, setSlideDirection] = useState<boolean>(true);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isCertificateDisabled, setIsCertificateDisabled] =
    useState<boolean>(false);
  const [certificateLoading, setCertificateLoading] = useState<boolean>(false);

  const [showTemplate, setShowTemplate] = useState<boolean>(false);
  const [frqTemplate, setFrqTemplate] = useState<String>("");
  const [frqTemplateQuestion, setFrqTemplateQuestion] = useState<String>("");
  const [frqTemplateImgs, setFrqTemplateImgs] = useState<Array<any>>([]);
  const [graderTabValue, setGraderTabValue] = useState("template");
  const dispatch = useAppDispatch();
  const handleBackToCourses = () => {
    dispatch(setShowGrader(false));
  };

  const updateUserProgressValue = (value: number) => {
    setUserProgressValue(value);
  };

  const loadUsersFrqAnswerSubmitted = async () => {
    const courseId = selectedCourse._id;
    const userId = selectedUser.userId;
    if (userId && courseId) {
      setLoadingUserFrqAnswers(true);
      const frqResponses = await dispatch(
        retrieveStudentsSolutionInCourseForFreeResponseAction({
          userId: userId,
          courseId: courseId,
        })
      );
      // console.log("Response from get Frq api ", frqResponses);
      if (frqResponses?.payload?.usersFrqSolutions) {
        setSelectedUserFrqResponse(frqResponses?.payload?.usersFrqSolutions);
      } else {
        setSelectedUserFrqResponse([]);
      }
      setLoadingUserFrqAnswers(false);
    }
  };

  const loadUsersMcqAnswerSubmitted = async () => {
    const courseId: string = selectedCourse._id;
    const userId = selectedUser.userId;
    if (userId && courseId) {
      setLoadingUserMcqAnswers(true);
      const mcqResponse = await dispatch(
        retrieveStudentsSolutionInCourseForMcqAction({
          userId: userId,
          courseId: courseId,
        })
      );
      if (mcqResponse?.payload?.usersMcqSolutions) {
        setSelectedUserMcqResponse(mcqResponse?.payload?.usersMcqSolutions);
      } else {
        setSelectedUserMcqResponse([]);
      }
      setLoadingUserMcqAnswers(false);
    }
  };

  const initialLoader = async () => {
    if (selectedUser?.userId) {
      updateUserProgressValue(parseFloat(selectedUser?.progress));
      await loadUsersMcqAnswerSubmitted();
      await loadUsersFrqAnswerSubmitted();
      dispatch(
        removeCourseIdFromRefreshingCourses({ courseId: selectedCourse._id })
      );
    }
  };
  useEffect(() => {
    initialLoader();
  }, [selectedUser]);

  // useEffect(() => {
  //   if (selectedUserFrqResponse?.length < 1) {
  //     setIsCertificateDisabled(true);
  //     return;
  //   }
  //   selectedUserFrqResponse.forEach((frqResponse) => {
  //     if (frqResponse.reviewStatus.toLowerCase() === "pending") {
  //       setIsCertificateDisabled(true);
  //       return;
  //     }
  //   });
  //   setIsCertificateDisabled(false);
  // }, [selectedUserFrqResponse]);

  const handleCourseRefresh = async (loadResponseOnly?: boolean) => {
    if (loadResponseOnly) {
      setLoadingUserMcqAnswers(true);
      setLoadingUserFrqAnswers(true);
      setSelectedUserMcqResponse([]);
      setSelectedUserMcqResponse([]);
    } else {
      dispatch(
        addCourseIdInToRefreshingCourses({ courseId: selectedCourse._id })
      );
    }
    await dispatch(
      loadSingleCourseDataAction({ courseId: selectedCourse._id })
    );
  };
  const resetUserData = async () => {
    setUserProgressValue(0);
    await handleCourseRefresh(true);
  };
  const handleNextUserClick = () => {
    resetUserData();
    const currentIdex = userList.findIndex(
      (user: any) => user.frontEndId === selectedUser.frontEndId
    );
    if (currentIdex >= userList.length - 1) {
      return;
    }
    setSlideDirection(true);
    setSlideIn(false);
    dispatch(setSelectedUser(userList[currentIdex + 1]));
    setTimeout(() => {
      setSlideDirection(false);
      setSlideIn(true);
    }, 300);
  };

  const handlePreviousUserClick = () => {
    resetUserData();
    const currentIdex = userList.findIndex(
      (user) => user.frontEndId === selectedUser.frontEndId
    );
    if (currentIdex <= 0) {
      return;
    }

    setSlideDirection(false);
    setSlideIn(false);
    dispatch(setSelectedUser(userList[currentIdex - 1]));
    setTimeout(() => {
      setSlideDirection(true);
      setSlideIn(true);
    }, 300);
  };

  const getDocumentDefinitions = async (): Promise<TDocumentDefinitions> => {
    return new Promise(async (resolve, reject) => {
      try {
        const certificate = await getBase64ImageFromURL(
          DOMAIN_CONFIG_OBJ.admin.certificate.certBgImageUrl
        );
        const date = new Date();
        const formattedDate = `${date.getMonth()}/${date.getDate()}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`;
        const documentDefinitions: TDocumentDefinitions = {
          // Background image
          background: function (currentPage, pageSize) {
            return {
              image: (certificate as string) || "",
              width: pageSize.width,
              height: pageSize.height,
              absolutePosition: { x: 0, y: 0 },
            };
          },
          /** metadata */
          info: {
            title: "Certificate of completion",
            author: DOMAIN_CONFIG_OBJ.apiDomain,
            subject: "Certificate",
            keywords: "Certificate",
          },
          content: [
            {
              text: `${selectedUser.firstName} ${selectedUser.lastName}`,
              style: "name",
            },

            { text: `${selectedCourse.courseName}`, style: "courseTitle" },

            { text: `${formattedDate}`, style: "date" },
          ],
          styles: DOMAIN_CONFIG_OBJ.admin.certificate
            .styles as unknown as StyleDictionary,
          pageOrientation: DOMAIN_CONFIG_OBJ.admin.certificate
            .pageOrientation as PageOrientation,
        };
        resolve(documentDefinitions);
      } catch (err) {
        console.log("Failed to create document definitions", err);
        reject(err);
      }
    });
  };

  const sendCertificate = async () => {
    setCertificateLoading(true);
    const docDef = await getDocumentDefinitions();
    const generatedPdf = pdfmake.createPdf(docDef);
    generatedPdf.getBase64((res) => {
      dispatch(
        sendAttachedEmailAction({
          to: selectedUser.email,
          subject: `Certificate of Completion for ${selectedUser.firstName} ${selectedUser.lastName}`,
          message: "Congratulations on completing the course",
          base64Data: res,
        })
      )
        .then(async () => {
          dispatch(
            handleSnackbarOpen({
              severity: "success",
              message: `Successfully sent certificate to ${selectedUser.firstName} ${selectedUser.lastName} at ${selectedUser.email}`,
            })
          );
          setCertificateLoading(false);
          await dispatch(
            updateUserCertificateAction({
              userId: selectedUser.userId,
              courseId: selectedCourse._id,
              status: true,
            })
          );
          await handleCourseRefresh(false);
        })
        .catch(() => {
          dispatch(
            handleSnackbarOpen({
              severity: "error",
              message: `Unable to send Certificate of completion !`,
            })
          );
        });
    });
  };

  const viewCertificate = async () => {
    setCertificateLoading(true);
    const docDef: TDocumentDefinitions = await getDocumentDefinitions();
    const generatedPdf = pdfmake.createPdf(docDef);
    generatedPdf.open();
    setCertificateLoading(false);
  };

  const handleViewTemplate = (
    templateQuiz: string,
    tempateText?: string,
    templateImages?: any
  ) => {
    setFrqTemplateQuestion(templateQuiz);
    if (tempateText) {
      setFrqTemplate(tempateText);
    } else {
      setFrqTemplate("");
    }
    if (templateImages?.length) {
      setFrqTemplateImgs(templateImages);
    } else {
      setFrqTemplateImgs([]);
    }
    setGraderTabValue("template");
    setShowTemplate(true);
  };
  const handleCloseTemplateView = () => {
    setFrqTemplate("");
    setFrqTemplateQuestion("");
    setFrqTemplateImgs([]);
    setShowTemplate(false);
  };

  const getImage = (url: any) => {
    const imgExt = url.split(".").pop();
    if (imageFormats.includes(imgExt)) {
      return url;
    } else if (url.startsWith("data:image")) {
      return url;
    } else {
      return noPreviewAvailable;
    }
  };
  return (
    <Grid
      container
      sx={{
        padding: { lg: "1em", md: ".5em", sm: "1em 5px", xs: "1em 5px" },
        gap: "1em",
      }}
      cy-data="admin-course-grader-container"
    >
      <Grid
        item
        container
        xs={12}
        sx={{
          display: "flex",
          flexDirection: "row",
          overflow: "hidden",
          gap: "1em",
        }}
      >
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography
            onClick={() => handleBackToCourses()}
            sx={{
              fontSize: "20px",
              fontWeight: "900",
              lineHeight: "27px",
              letterSpacing: "0em",
              textAlign: "left",
              cursor: "pointer",
              color: "var(--text-gray2)",
              ":hover": {
                color: "var(--text-primary-clr2)",
                scale: 1.2,
              },
            }}
          >{`My courses`}</Typography>
          <Box sx={{ display: "flex", gap: "1em", flexWrap: "wrap" }}>
            <Button
              variant="qbookStyledBtn"
              onClick={() => handleBackToCourses()}
              title="View Course List"
              sx={{ height: "26px" }}
              cy-data="admin-course-grader-go-back-btn"
            >
              {/* <KeyboardBackspaceIcon /> */}Go back
            </Button>
            <Button
              variant="qbookStyledBtn"
              sx={{ height: "26px" }}
              onClick={() => handleCourseRefresh()}
              title="Refresh Course"
              cy-data="admin-course-grader-refresh-btn"
            >
              {/* <CachedIcon /> */} Refresh
            </Button>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: "20px", sm: "25px", md: "35px", lg: "39px" },
              fontWeight: "900",
              lineHeight: "52px",
              letterSpacing: "0em",
              textAlign: "left",
              width: { xs: "300px", sm: "300px", md: "500px", lg: "100%" },
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
              color: "var(--text-primary-clr1)",
            }}
            cy-data="admin-course-grader-course-name"
          >
            {selectedCourse.courseName}
          </Typography>
        </Grid>
      </Grid>
      <Grid item container xs={12} gap={1} justifyContent="space-around">
        <Grid
          item
          container
          lg={6.8}
          md={6.8}
          sm={12}
          xs={12}
          sx={{
            boxShadow: "0px 6px 16px 0px #0000001F",
            borderRadius: "10px",
            minHeight: "500px",
            overflow: "auto",
            alignItems: "flex-start",
            alignContent: "flex-start",
            gap: "1em",
            padding: { lg: "1em", md: ".5em", sm: "1em .5em", xs: "1em .5em" },
          }}
        >
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: { lg: "1em", md: ".5em", sm: "0", xs: "0" },
              maxHeight: "80px",
            }}
          >
            {userList.findIndex(
              (user) => user.userId === selectedUser.userId
            ) <= 0 ? (
              <Box
                sx={{
                  minWidth: { xs: "6px", sm: "6px", md: "6px", lg: "64px" },
                }}
              />
            ) : (
              <Box>
                <Button
                  sx={{
                    textTransform: "none",
                    color: "var(--text-primary-clr1)",
                    fontWeight: "600",
                    maxHeight: "30px",
                    minHeight: "30px",
                    padding: "0 5px",
                    display: { xs: "none", sm: "none", md: "none", lg: "flex" },
                  }}
                  onClick={() => handlePreviousUserClick()}
                  cy-data="admin-course-grader-previous-btn"
                >
                  {"< Prior user"}
                </Button>
                <IconButton
                  sx={{
                    color: "var(--text-primary-clr1)",
                    display: { xs: "flex", sm: "flex", md: "flex", lg: "none" },
                  }}
                  onClick={() => handlePreviousUserClick()}
                >
                  <KeyboardArrowLeftIcon />
                </IconButton>
              </Box>
            )}

            <Typography
              sx={{
                fontSize: { xs: "18px", sm: "20px", md: "24px", lg: "24px" },
                fontWeight: 700,
                lineHeight: "32px",
                textAlign: "center",
                width: { xs: "200px", sm: "200px", md: "500px" },
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
                color: "var(--text-primary-clr1)",
              }}
              cy-data="admin-course-grader-user-name"
            >
              {selectedUser.firstName} {selectedUser.lastName}
            </Typography>
            {userList.findIndex(
              (user) => user.userId === selectedUser.userId
            ) >=
            userList.length - 1 ? (
              <Box
                sx={{
                  minWidth: { xs: "6px", sm: "6px", md: "6px", lg: "64px" },
                }}
              />
            ) : (
              <Box>
                <Button
                  sx={{
                    textTransform: "none",
                    color: "var(--text-primary-clr1)",
                    fontWeight: "600",
                    maxHeight: "30px",
                    minHeight: "30px",
                    padding: "0 5px",
                    display: { xs: "none", sm: "none", md: "none", lg: "flex" },
                  }}
                  onClick={() => handleNextUserClick()}
                  cy-data="admin-course-grader-next-btn"
                >
                  {"Next user >"}
                </Button>
                <IconButton
                  sx={{
                    color: "var(--text-primary-clr1)",
                    display: { xs: "flex", sm: "flex", md: "flex", lg: "none" },
                  }}
                  onClick={() => handleNextUserClick()}
                >
                  <KeyboardArrowRightIcon />
                </IconButton>
              </Box>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              maxHeight: "66px",
            }}
          >
            <Button
              sx={(theme) => ({
                minWidth: "90px",
                minHeight: "66px",
                maxHeight: "66px",
                padding: "0",
                borderRadius: "12px",
                backgroundColor: "var(--accent-clr1)",
                color: "#ffffff",
                fontWeight: 700,
                fontSize: "17px",
                ":hover": {
                  color: "var(--accent-clr1)",
                  border: `1px solid 'var(--accent-clr1)'`,
                },
              })}
              cy-data="admin-course-grader-progress-btn"
            >
              {refreshingCourses?.includes(selectedCourse?._id) ||
              loadingUserFrqAnswers ||
              loadingUserMcqAnswers ? (
                <CircularProgress sx={{ color: "white" }} />
              ) : (
                `${parseFloat(userProgressValue.toString()).toFixed(2) || 0}/
              ${selectedCourse?.courseProgressPoints || 100}`
              )}
            </Button>
          </Grid>
          <Slide direction={slideDirection ? "left" : "right"} in={slideIn}>
            <Grid
              item
              container
              xs={12}
              p={1}
              sx={{
                minHeight: "60%",
                maxHeight: "500px",
                overflowX: "hidden",
                overflowY: "auto",
                gap: "1em",
              }}
            >
              <Grid
                item
                container
                xs={12}
                sx={{ minHeight: "30%", alignContent: "flex-start" }}
              >
                <Grid
                  item
                  xs={12}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  minHeight={50}
                  cy-data="admin-course-grader-frq-container"
                >
                  <Typography color="var(--text-primary-clr1)">
                    Free Response
                  </Typography>
                  <Button
                    variant="qbookStyledBtn"
                    sx={{ height: "26px", textTransform: "none" }}
                    // onClick={() => loadUsersFrqAnswerSubmitted()}
                    onClick={async () => {
                      handleCourseRefresh(true);
                    }}
                  >
                    Refresh User Responses
                  </Button>
                </Grid>
                {loadingUserFrqAnswers ||
                refreshingCourses?.includes(selectedCourse?._id) ? (
                  Array(3)
                    .fill("")
                    .map((item: any, index: any) => (
                      <Skeleton
                        variant="text"
                        width="100%"
                        height={50}
                        key={index}
                      />
                    ))
                ) : selectedUserFrqResponse.length === 0 ? (
                  <Typography variant="body1" color="text.primary">
                    No solutions submitted by user
                  </Typography>
                ) : (
                  selectedUserFrqResponse?.map(
                    (frqResponse: any, index: number) => (
                      <AnswerAccordion
                        key={`free-response-accordion-${index}`}
                        questionThemeColor="blue"
                        questionType={QuestionTypes.frq}
                        solution={frqResponse}
                        loadUsersAnswerSubmitted={loadUsersFrqAnswerSubmitted}
                        handleViewTemplate={handleViewTemplate}
                        getImage={getImage}
                        setUserProgressValue={updateUserProgressValue}
                        userProgressValue={userProgressValue}
                      />
                    )
                  )
                )}
              </Grid>
              {/* <Grid item container xs={12} sx={{ minHeight: "30%" }}>
                <Grid
                  item
                  xs={12}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  minHeight={50}
                >
                  <Typography>Code Questions</Typography>
                  <Button
                    variant="qbookStyledBtn"
                    onClick={() => loadUsersFrqAnswerSubmitted()}
                  >
                    Refresh
                  </Button>
                </Grid>
                {loadingUserAnswers
                  ? Array(3)
                      .fill("")
                      .map((item: any, index: any) => (
                        <Skeleton
                          variant="text"
                          width="100%"
                          height={50}
                          key={index}
                        />
                      ))
                  : Array(3)
                      .fill("")
                      .map((item: any, index: any) => (
                        <AnswerAccordion
                          questionThemeColor="pink"
                          questionType={QuestionTypes.}
                          question={{ customId: "test" }}
                        />
                      ))}
              </Grid> */}
              <Grid item container xs={12} sx={{ minHeight: "30%" }}>
                <Grid
                  item
                  xs={12}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  minHeight={50}
                  cy-data="admin-course-grader-mcq-container"
                >
                  <Typography color="var(--text-primary-clr1)">
                    Multiple Choice Question
                  </Typography>
                  {/* <Button
                    variant="qbookStyledBtn"
                    // onClick={() => loadUsersMcqAnswerSubmitted()}
                    sx={{ height: "26px", textTransform: "none" }}
                    onClick={async ()=>{await dispatch(
                      loadSingleCourseDataAction({ courseId: selectedCourse._id })
                    );}}
                  >
                    Refresh
                  </Button> */}
                </Grid>
                {loadingUserMcqAnswers ||
                refreshingCourses?.includes(selectedCourse?._id) ? (
                  Array(3)
                    .fill("")
                    .map((item: any, index: any) => (
                      <Skeleton
                        variant="text"
                        width="100%"
                        height={50}
                        key={index}
                      />
                    ))
                ) : selectedUserMcqResponse.length === 0 ? (
                  <Typography variant="body1" color="var(--text-primary-clr1)">
                    No solutions submitted by user
                  </Typography>
                ) : (
                  selectedUserMcqResponse.map(
                    (mcqResponse: any, index: any) => (
                      <AnswerAccordion
                        key={`mcq-response-accordion-${index}`}
                        questionThemeColor="violet"
                        questionType={QuestionTypes.mcq}
                        loadUsersAnswerSubmitted={loadUsersMcqAnswerSubmitted}
                        solution={mcqResponse}
                        getImage={getImage}
                        setUserProgressValue={updateUserProgressValue}
                        userProgressValue={userProgressValue}
                      />
                    )
                  )
                )}
              </Grid>
              {loadingUserFrqAnswers ||
              loadingUserMcqAnswers ||
              refreshingCourses?.includes(selectedCourse?._id) ? (
                <Grid
                  item
                  container
                  xs={12}
                  justifyContent="flex-end"
                  alignItems="center"
                  spacing={1}
                  gap={1}
                >
                  {Array(2)
                    .fill("")
                    .map((_, index) => {
                      return (
                        <Skeleton
                          sx={{
                            borderRadius: "4px",
                            minWidth: "150px",
                            minHeight: "60px",
                          }}
                          key={`certificate_btn_loader_${index}`}
                        />
                      );
                    })}
                </Grid>
              ) : (
                <Grid
                  item
                  container
                  xs={12}
                  justifyContent="flex-end"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item sx={{ color: "var(--accent-clr1)" }}>
                    {certificateLoading && (
                      <CircularProgress
                        color="inherit"
                        size="24px"
                        sx={{ width: "16px", height: "16px" }}
                      />
                    )}
                  </Grid>
                  <Grid item>
                    <Button
                      variant="outlined"
                      startIcon={<CertificateIcon />}
                      sx={{ textTransform: "none" }}
                      onClick={viewCertificate}
                      disabled={isCertificateDisabled || certificateLoading}
                      cy-data="admin-course-grader-view-certificate-btn"
                    >
                      View Certificate
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      startIcon={<CertificateIcon />}
                      sx={{ textTransform: "none" }}
                      onClick={sendCertificate}
                      disabled={isCertificateDisabled || certificateLoading}
                      cy-data="admin-course-grader-send-certificate-btn"
                    >
                      Send Certificate
                    </Button>
                  </Grid>
                  {selectedUser?.isCertificateSent && (
                    <Grid item>
                      <Button
                        startIcon={<VerifiedUserIcon />}
                        sx={{
                          textTransform: "none",
                          color: "green",
                          // border: "1px solid green",
                          // ":hover": {
                          //   border: "1px solid green",
                          // },
                        }}
                      >
                        Already Send
                      </Button>
                    </Grid>
                  )}
                </Grid>
              )}
            </Grid>
          </Slide>
        </Grid>
        <Grid
          item
          lg={5}
          md={5}
          sm={12}
          xs={12}
          sx={{
            padding: { lg: "1em", md: ".5em", sm: "1em .5em", xs: "1em .5em" },
            position: "relative",
            display: "flex",
            flexDirection: "column",
            gap: "1em",
            // paddingTop: showTemplate ? "unset" : "1em",
            boxShadow: "0px 6px 16px 0px #0000001F",
            borderRadius: "10px",
            height: "fit-content",
          }}
        >
          {/* tab ui start */}
          {showTemplate ? (
            <Box
              className="box"
              sx={{
                width: "inherit",
                // boxShadow: 1,
                // borderRadius: "5px",
                // padding: "10px",
              }}
              cy-data="admin-course-grader-tab-container"
            >
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Tabs
                  value={graderTabValue}
                  variant="scrollable"
                  scrollButtons={false}
                  aria-label="scrollable prevent tabs example"
                  sx={{
                    "& .MuiTab-root": {
                      minWidth: 0,
                    },
                    marginBottom: "1em",
                  }}
                >
                  <Tab
                    disableRipple
                    value="template"
                    icon={
                      <DragHandleIcon
                        sx={{ fontSize: "2rem", cursor: "grab" }}
                      />
                    }
                    onClick={() => setGraderTabValue("template")}
                    cy-data="admin-course-grader-frq-template-tab"
                  />
                  <Tab
                    disableRipple
                    icon={
                      <GroupIcon sx={{ fontSize: "2rem", cursor: "grab" }} />
                    }
                    value="users"
                    onClick={() => setGraderTabValue("users")}
                    cy-data="admin-course-grader-frq-user-tab"
                  />
                  {frqTemplateImgs.length > 0 && (
                    <Tab
                      disableRipple
                      icon={
                        <ImageIcon sx={{ fontSize: "2rem", cursor: "grab" }} />
                      }
                      value="templateImage"
                      onClick={() => setGraderTabValue("templateImage")}
                      cy-data="admin-course-grader-frq-template-image-tab"
                    />
                  )}
                </Tabs>
                <IconButton
                  onClick={handleCloseTemplateView}
                  title="Clear Template"
                  cy-data="admin-course-grader-template-template-tab-close-btn"
                  sx={{ width: "25px", height: "25px" }}
                >
                  <CloseIcon sx={{ fontSize: "1rem" }} />
                </IconButton>
              </Box>

              {graderTabValue === "template" ? (
                <Box
                  className="box"
                  sx={{
                    width: "inherit",
                    boxShadow: 1,
                    borderRadius: "5px",
                    padding: "10px",
                  }}
                  cy-data="admin-course-grader-frq-template-container"
                >
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Box sx={{ display: "flex", gap: "1em" }}>
                      <Typography variant="h6">Template Response</Typography>
                    </Box>
                  </Box>
                  <Box sx={{ display: "flex", gap: "5px" }}>
                    <p>
                      <b>Question : </b>
                      {frqTemplateQuestion || "Not Selected"}
                    </p>
                  </Box>
                  <br></br>
                  <p>
                    <b>Solution : </b>
                    {frqTemplate || "Template not available"}
                  </p>
                </Box>
              ) : graderTabValue === "templateImage" ? (
                <Box
                  sx={{
                    maxHeight: "500px",
                    overflowX: "hidden",
                    overflowY: "auto",
                    width: "inherit",
                    boxShadow: 1,
                    borderRadius: "5px",
                    padding: "10px",
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "10px",
                  }}
                  cy-data="admin-course-grader-frq-template-image-container"
                >
                  {frqTemplateImgs?.length === 0 ? (
                    <Typography variant="body2">
                      No documents available
                    </Typography>
                  ) : (
                    frqTemplateImgs?.map((docItem: any, index: any) => {
                      return (
                        <Box
                          sx={{
                            width: "150px",
                            height: "150px",
                            borderRadius: "5px",
                            position: "relative",
                            overflow: "hidden",
                            cursor: "pointer",
                            ":hover": {
                              border: "1px solid var(--accent-clr2)",
                            },
                          }}
                        >
                          <img
                            src={getImage(docItem.questionTemplateFileUrl)}
                            alt="docsImg"
                            key={`docsImg${index}`}
                            width="100%"
                            height="100%"
                          />
                        </Box>
                      );
                    })
                  )}
                </Box>
              ) : (
                <Box
                  sx={{
                    maxHeight: "500px",
                    overflowX: "hidden",
                    overflowY: "auto",
                    width: "inherit",
                    boxShadow: 1,
                    borderRadius: "5px",
                    padding: "10px",
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "10px",
                  }}
                  cy-data="admin-course-grader-frq-template-user-container"
                >
                  <UserTableList
                    graderTable={true}
                    itemId={selectedCourse?._id}
                  />
                </Box>
              )}
            </Box>
          ) : (
            <UserTableList graderTable={true} itemId={selectedCourse?._id} />
          )}
          {/* tab ui end */}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default GraderUi;
