import React, { Suspense, useEffect } from "react";
import { ThemeProvider } from "@mui/material";
import {
    createBrowserRouter,
    Navigate,
    RouterProvider,
} from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../reduxStore/hooks";
import { useBeforeRender } from "./hooks/useBeforeRender";
import {
    fetchUser,
    getUserIdentityAction,
} from "../reduxStore/middleware/authMiddleware";
import { getUserIdentity } from "../reduxStore/services/authService";
import { toggleTheme } from "../reduxStore/features/themeSlice";

// Constants imported from external files
import { QBOOK_DOMAIN_VALUES } from "../utils/constants/domainNameValues";
import { DOMAIN_CONFIG_OBJ } from "../utils/axiosInstance";

// Component imports
import Auth from "./pages/login";
import Layout from "./pages/layout/index";
import AdminLayout from "./pages/Admin";
// import AdminDashBoard from "./pages/Admin/components/dashboard";
import CourseDashBoard from "./pages/Admin/components/course";
import BlogDashBoard from "./pages/Admin/components/blog";
import ErrorBoundaryPage from "./pages/errorPage";
import Loading from "./components/loading";
import StyledSnackbar from "./components/Snackbar";
import { darkTheme, lightTheme } from "../utils/MUITheme/theme";
import Landing from "./pages/landingPage";
import EditProfile from "./pages/editProfile";

// Lazy-loaded components
const Learn = React.lazy(() => import("./pages/learn/index"));
const Oauth = React.lazy(() => import("./pages/login/OAuthLogin"));
const Blogs = React.lazy(() => import("./pages/blogs"));
const Termly = React.lazy(() => import("./pages/legal"));

function App() {
    const dispatch = useAppDispatch();
    const { isDark } = useAppSelector((state) => state.theme);
    const { isAuthenticated, userData } = useAppSelector((state) => state.auth);

    // Use custom hook to handle tasks before rendering
    useBeforeRender(() => {
        window.addEventListener("error", (e) => {
            if (e) {
                const resizeObserverErrDiv = document.getElementById(
                    "webpack-dev-server-client-overlay-div"
                );
                const resizeObserverErr = document.getElementById(
                    "webpack-dev-server-client-overlay"
                );
                if (resizeObserverErr)
                    resizeObserverErr.className = "hide-resize-observer";
                if (resizeObserverErrDiv)
                    resizeObserverErrDiv.className = "hide-resize-observer";
            }
        });
    }, []);

    // Load authentication data for most routes (excluding OAuth)
    const initialLoader = async () => {
        if (window.location.pathname !== "/oauth") {
            dispatch(fetchUser(true)).then((data) => {
                // Fetch additional user data from qbraid API if login is successful
                if (data.type === "auth/fetchUser/fulfilled") {
                    dispatch(getUserIdentityAction());
                }
            });
        }
    };
    // Perform initial loading and focus event listener for auth
    useEffect(() => {
        initialLoader();
        window.addEventListener("focus", () => {
            dispatch(fetchUser(false));
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Set theme based on QBOOK_DOMAIN and local storage preference
    useEffect(() => {
        if (DOMAIN_CONFIG_OBJ.domainName === QBOOK_DOMAIN_VALUES.quera) {
            // dispatch(toggleTheme(true));
            // document?.querySelector("html")?.toggleAttribute("data-dark-mode", true);
            document
                ?.querySelector("html")
                ?.toggleAttribute("data-quera-mode", true);
        }
        if (DOMAIN_CONFIG_OBJ.domainName === QBOOK_DOMAIN_VALUES.qusteam) {
            document
                ?.querySelector("html")
                ?.toggleAttribute("data-qusteam-mode", true);
        }
        const themeValue = localStorage.getItem("qbookTheme");
        if (themeValue === "dark") {
            dispatch(toggleTheme(true));
            document
                ?.querySelector("html")
                ?.toggleAttribute("data-dark-mode", true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const router = createBrowserRouter([
        {
            path: "/",
            element: (
                <Suspense fallback={<Loading />}>
                    <Layout />
                </Suspense>
            ),
            errorElement: <ErrorBoundaryPage />,
            children: [
                {
                    index: true,
                    path: "",
                    element: <Landing />,
                },
                {
                    path: "learn/*",
                    element: <Learn />,
                },
                {
                    path: "blogs/*",
                    element: <Blogs />,
                },
                {
                    path: "blog/*",
                    element: <Blogs />,
                },
                { path: "profile/*", element: <EditProfile /> },
                {
                    path: "terms-and-conditions",
                    element: (
                        <Termly dataId="bd885a1a-cf4d-4cfd-bc3c-eb383bab2afe" />
                    ),
                },
                {
                    path: "privacy-policy",
                    element: (
                        <Termly dataId="aab5c6d8-8f23-4de0-bf15-093b9363690b" />
                    ),
                },
                {
                    path: "cookie-policy",
                    element: (
                        <Termly dataId="b91f6ee2-e92a-46be-b75d-4d2f0d897ab3" />
                    ),
                },
                {
                    path: "*",
                    element: <Navigate to="/" />,
                },
            ],
        },
        // admin routes
        {
            path: "/admin",
            element: (
                <Suspense fallback={<Loading />}>
                    <AdminLayout />
                </Suspense>
            ),
            loader: async () => {
                try {
                    if (userData) {
                        return { data: userData };
                    }
                    return await getUserIdentity();
                } catch (error) {
                    return null;
                }
            },
            errorElement: <ErrorBoundaryPage />,
            children: [
                {
                    index: true,
                    path: "",
                    element: <CourseDashBoard />,
                },
                {
                    index: true,
                    path: "courses/*",
                    element: <CourseDashBoard />,
                },
                {
                    path: "blogs/*",
                    element: <BlogDashBoard />,
                },
                {
                    path: "*",
                    element: <Navigate to={"/admin"} />,
                },
            ],
        },
        // if user is authenticated auth routes will redirect to default url
        {
            path: "/login/*",
            element: (
                <Suspense fallback={<Loading />}>
                    {!isAuthenticated ? <Auth /> : <Navigate to="/" />}
                </Suspense>
            ),
            errorElement: <ErrorBoundaryPage />,
        },
        {
            path: "/oauth",
            element: (
                <Suspense fallback={<Loading />}>
                    <Oauth />
                </Suspense>
            ),
            errorElement: <ErrorBoundaryPage />,
        },
        // all invalid routes redirected to defaulturl
        {
            path: "*",
            errorElement: <ErrorBoundaryPage />,
            element: <Navigate to="/" />,
        },
    ]);
    return (
        <ThemeProvider theme={isDark ? darkTheme : lightTheme}>
            <RouterProvider router={router} />
            <StyledSnackbar />
        </ThemeProvider>
    );
}

export default App;
