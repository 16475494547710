import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  bookmarkBlogAsync,
  copyBlogAsync,
  getBlogAsync,
  getMoreBlogsAsync,
  getUserBlogBookmarksAsync,
  upvoteBlogAsync,
} from "../services/blogsService";
import { handleSnackbarOpen } from "../features/snackbarSlice";
import { AxiosResponse } from "axios";

// get individual blog
export const getBlogAction = createAsyncThunk(
  "blogs/getBlogs",
  async ({ blogId }: { blogId: string }, thunkAPI) => {
    try {
      const response: AxiosResponse = await getBlogAsync(blogId);
      return thunkAPI.fulfillWithValue(response.data);
    } catch (error) {
      return thunkAPI.rejectWithValue({ message: "Cant retrieve blog" });
    }
  },
);

// get array of blogs publicly available
export const getMoreBlogsAction = createAsyncThunk(
  "blogs/getMoreBlogs",
  async ({ pageNumber }: { pageNumber: Number }, thunkAPI) => {
    try {
      const response: AxiosResponse = await getMoreBlogsAsync(pageNumber);
      return thunkAPI.fulfillWithValue(response.data);
    } catch (error) {
      return thunkAPI.rejectWithValue({ message: "Cant retrieve blogs" });
    }
  },
);

export const upvoteBlogAction = createAsyncThunk(
  "blogs/upvoteBlog",
  async ({ blogId }: { blogId: string }, thunkAPI) => {
    try {
      const response: AxiosResponse = await upvoteBlogAsync(blogId);
      return thunkAPI.fulfillWithValue(response.data);
    } catch (error) {
      return thunkAPI.rejectWithValue({ message: "Cant upvote blog" });
    }
  },
);

// bookmark a particular blog
// call the same action if bookmark need to be removed from the particular blog
export const bookmarkBlogAction = createAsyncThunk(
  "blogs/bookmarkBlog",
  async ({ blogId, userId }: { blogId: string; userId: string }, thunkAPI) => {
    try {
      const response: AxiosResponse = await bookmarkBlogAsync(blogId);
      if (response.status === 200) {
        thunkAPI.dispatch(getBookmarkedBlogAction());
        const isAdded = response.data.message.includes("removed")
          ? false
          : true;

        if (isAdded) {
          thunkAPI.dispatch(
            handleSnackbarOpen({ message: "Bookmarked!", severity: "success" }),
          );
        } else {
          thunkAPI.dispatch(
            handleSnackbarOpen({
              message: "Bookmark removed!",
              severity: "success",
            }),
          );
        }

        return thunkAPI.fulfillWithValue({
          blogId: blogId,
          userId: userId,
          isAdded: isAdded,
        });
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ message: "Cant bookmark blog" });
    }
  },
);

export const copyBlogAction = createAsyncThunk(
  "blogs/copyBlog",
  async ({ filePath }: { filePath: string }, thunkAPI) => {
    try {
      const response: AxiosResponse = await copyBlogAsync(filePath);
      return thunkAPI.fulfillWithValue(response.data);
    } catch (error) {
      return thunkAPI.rejectWithValue({ message: "Cant bookmark blog" });
    }
  },
);

// get all user's bookmarked blogs
export const getBookmarkedBlogAction = createAsyncThunk(
  "blogs/getBookmarkedBlog",
  async (_, thunkAPI) => {
    try {
      const response: AxiosResponse = await getUserBlogBookmarksAsync();
      return thunkAPI.fulfillWithValue(response.data);
    } catch (err) {
      return thunkAPI.rejectWithValue({
        message: "Cant retrieve bookmarked blogs",
      });
    }
  },
);
